import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Toast from '../reusable/Toast';
import styles from '../../assets/css/Consult.module.css'
import { convertToCalendarSpecificDate, ViewCalendarDate, getTokenFromLocalStorage, isValidDate } from '../../helpers/index'
import Loader from '../reusable/Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

const ConsultDetails = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { consults, mode } = state || { consults: null, mode: 'add' };
    const [subMode, setSubMode] = useState(mode === 'add' ? 'edit' : 'view');

    const [hospitalfacilityname, setHospitalFacilityName] = useState(consults?.hospitalfacilityname || '');
    const [roomnumber, setRoomNumber] = useState(consults?.roomnumber || '');
    const [firstname, setFirstName] = useState(consults?.firstname || '');
    const [lastname, setLastName] = useState(consults?.lastname || '');
    const [dob, setDob] = useState(consults?.dob ? convertToCalendarSpecificDate(consults.dob) : '');
    const [daterequested, setDateRequested] = useState(
        consults?.daterequested
            ? convertToCalendarSpecificDate(consults.daterequested)
            : convertToCalendarSpecificDate(new Date().toISOString())
    );
    const [timerequested, setTimeRequested] = useState(
        consults?.timerequested ||
        new Date().toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        })
    );
    const [visitdate, setVisitDate] = useState(consults?.visitdate ? convertToCalendarSpecificDate(consults.visitdate) : '');
    const [assignedprovider, setAssignedProvider] = useState(consults?.assignedprovider || '');
    const [insurancecarrier, setInsuranceCarrier] = useState(consults?.insurancecarrier || '');
    const [rehabdiagnosis, setRehabDiagnosis] = useState(consults?.rehabdiagnosis || '');
    const [rehabrecs, setRehabRecs] = useState(consults?.rehabrecs || '');
    const [notes, setNotes] = useState(consults?.notes || '');
    const [initialconsultcomplete, setInitialConsultComplete] = useState(consults?.initialconsultcomplete || '');
    const [status, setStatus] = useState(consults?.status || 'open');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const showToastMessage = (message, type) => {
        setToastMessage(message);
        setToastType(type);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const handleConsultOnChange = (field, value) => {
        const setters = {
            hospitalfacilityname: setHospitalFacilityName,
            roomnumber: setRoomNumber,
            firstname: setFirstName,
            lastname: setLastName,
            dob: setDob,
            daterequested: setDateRequested,
            timerequested: setTimeRequested,
            visitdate: setVisitDate,
            assignedprovider: setAssignedProvider,
            insurancecarrier: setInsuranceCarrier,
            rehabdiagnosis: setRehabDiagnosis,
            rehabrecs: setRehabRecs,
            notes: setNotes,
            initialconsultcomplete: setInitialConsultComplete,
            status: setStatus,
        };

        if (setters[field]) {
            setters[field](value);
        }
    };

    const handleBack = () => {
        navigate('/consults-tracking-table');
    };

    const handleDates = (date) => {
        if (!date) return '';
        if (mode === 'view&edit') {
            return subMode === 'edit'
                ? convertToCalendarSpecificDate(date)
                : ViewCalendarDate(date);
        }
        if (mode === 'add') {
            return ViewCalendarDate(date);
        }
        return '';
    }


    const validateForm = () => {
        if (!hospitalfacilityname) return 'Please enter Hospital facility name';
        if (!firstname) return 'Please enter first name';
        if (!lastname) return 'Please enter last name';
        if (!daterequested) return 'Please select Date request';
        if (!timerequested) return 'Please select Time request';
        if (!assignedprovider) return 'Please enter Assigned Provider Name';
        return null;
    };

    const submitForm = async () => {
        const validationError = validateForm();
        if (validationError) {
            showToastMessage(validationError, 'error');
            return;
        }

        const formData = new URLSearchParams();
        if (consults?.id) formData.append('id', consults.id);

        const fields = {
            hospitalfacilityname, roomnumber, firstname, lastname, dob,
            daterequested, timerequested, visitdate, assignedprovider,
            insurancecarrier, rehabdiagnosis, rehabrecs, notes,
            initialconsultcomplete, status
        };

        Object.entries(fields).forEach(([key, value]) => {
            if (value) formData.append(key, value);
        });

        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                showToastMessage('Operation completed successfully', 'success');
                setTimeout(() => {
                    navigate('/consults-tracking-table');
                }, 2000);

            } else {
                showToastMessage('Operation failed', 'error');
            }
        } catch (error) {
            showToastMessage('An error occurred', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.mainContainer}>
                <div className="NavigationContainer">
                    <div className="back-button-container">
                        <BackIcon onClick={handleBack} className="back-button" />
                    </div>
                    {mode !== 'add' &&
                        <div className="toggle-container">
                            <div className="toggle" onClick={() => setSubMode(subMode === 'view' ? 'edit' : 'view')}>
                                <div className={`toggle-slider ${subMode === 'edit' ? 'active' : ''}`}></div>
                            </div>
                            <span>{subMode === 'view' ? 'View' : 'Edit'} Mode</span>
                        </div>}
                </div>
                <div className={styles.whiteContainer}>
                    <div style={{
                        justifyContent: 'flex-start',
                        backgroundColor: 'white',
                        borderRadius: '40px',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }} className={styles.content}>
                        {showToast && <Toast message={toastMessage} type={toastType} />}
                        <form onSubmit={(e) => { e.preventDefault(); submitForm(); }} className={styles.subContent}>
                            <center><h3 style={{ marginBottom: '3rem', marginTop: '3rem' }}>Consult Details</h3></center>
                            <div className={styles.columnContainer}>
                                <div className={styles.inputWrapper}>
                                    <label htmlFor="hospitalFacilityName">Hospital Facility Name<span className={styles.required}>*</span></label>

                                    <select
                                        id="hospitalFacilityName"
                                        className={[styles.inputField, styles.select].join(' ')}
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={hospitalfacilityname ? hospitalfacilityname : ''}
                                        onChange={(e) => handleConsultOnChange('hospitalfacilityname', e.target.value)}
                                    >
                                        <option value="">Select Hospital Facility Name</option>
                                        <option value="Chippenham Hospital">Chippenham Hospital</option>
                                        <option value="Encompass Health Rehabilitation Hospital of Petersburg">Encompass Health Rehabilitation Hospital of Petersburg</option>
                                        <option value="Henrico Doctors Hospital">Henrico Doctors Hospital</option>
                                        <option value="Johnston Willis Hospital">Johnston Willis Hospital</option>
                                        <option value="Parham Doctors Hospital">Parham Doctors Hospital</option>
                                        <option value="Southside Regional Medical Center">Southside Regional Medical Center</option>
                                    </select>

                                    <label htmlFor="roomNumber">Room</label>
                                    <input
                                        id="roomNumber"
                                        className={styles.inputField}
                                        placeholder="Room Number"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        value={roomnumber ? roomnumber : ''}
                                        onChange={(e) => handleConsultOnChange('roomnumber', e.target.value)} />

                                    <label htmlFor="firstName">First Name<span className={styles.required}>*</span></label>
                                    <input
                                        id="firstName"
                                        className={styles.inputField}
                                        placeholder="First Name"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={firstname ? firstname.toString().charAt(0).toUpperCase() + firstname.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('firstname', e.target.value)} />

                                    <label htmlFor="lastName">Last Name<span className={styles.required}>*</span></label>
                                    <input
                                        id="lastName"
                                        className={styles.inputField}
                                        placeholder="Last Name"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={lastname ? lastname.toString().charAt(0).toUpperCase() + lastname.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('lastname', e.target.value)} />

                                    <label htmlFor="dob">Date of Birth</label>
                                    <input
                                        id="dob"
                                        placeholder="Date of Birth"
                                        type="date"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        className={styles.dateField}
                                        required
                                        value={dob && isValidDate(dob) ? handleDates(dob) : ''}
                                        onChange={(e) => handleConsultOnChange('dob', e.target.value)} />

                                    <label htmlFor="dateRequested">Date Requested<span className={styles.required}>*</span></label>
                                    <input
                                        id="dateRequested"
                                        placeholder="Date Requested"
                                        type="date"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        className={styles.dateField}
                                        required
                                        value={daterequested && isValidDate(daterequested) ? handleDates(daterequested) : ''}
                                        onChange={(e) => handleConsultOnChange('daterequested', e.target.value)} />

                                    <label htmlFor="timeRequested">Time Requested<span className={styles.required}>*</span></label>
                                    <input
                                        id="timeRequested"
                                        placeholder="Time Requested"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        className={styles.inputField}
                                        required
                                        value={timerequested ? timerequested : ''}
                                        onChange={(e) => handleConsultOnChange('timerequested', e.target.value)} />

                                </div>

                                <div className={styles.inputWrapper}>
                                    <label htmlFor="visitDate">Visit Date</label>
                                    <input
                                        id="visitDate"
                                        className={styles.dateField}
                                        placeholder="Visit Date"
                                        type="date"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        value={visitdate && isValidDate(visitdate) ? handleDates(visitdate) : ''}
                                        onChange={(e) => handleConsultOnChange('visitdate', e.target.value)} />

                                    <label htmlFor="assignedProvider">Assigned Provider<span className={styles.required}>*</span></label>
                                    <input
                                        id="assignedProvider"
                                        className={styles.inputField}
                                        placeholder="Assigned Provider"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={assignedprovider ? assignedprovider.toString().charAt(0).toUpperCase() + assignedprovider.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('assignedprovider', e.target.value)} />

                                    <label htmlFor="insuranceCarrier">Insurance Carrier</label>
                                    <input
                                        id="insuranceCarrier"
                                        className={styles.inputField}
                                        placeholder="Insurance Carrier"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={insurancecarrier ? insurancecarrier.toString().charAt(0).toUpperCase() + insurancecarrier.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('insurancecarrier', e.target.value)} />

                                    <label htmlFor="rehabDiagnosis">Rehab Diagnosis</label>
                                    <input
                                        id="rehabDiagnosis"
                                        className={styles.inputField}
                                        placeholder="Rehab Diagnosis"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={rehabdiagnosis ? rehabdiagnosis.toString().charAt(0).toUpperCase() + rehabdiagnosis.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('rehabdiagnosis', e.target.value)} />

                                    <label htmlFor="rehabRecs">Rehab Recs</label>
                                    <input
                                        id="rehabRecs"
                                        className={styles.inputField}
                                        placeholder="Rehab Recs"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={rehabrecs ? rehabrecs : ''}
                                        onChange={(e) => handleConsultOnChange('rehabrecs', e.target.value)} />

                                    <label htmlFor="notes">Notes</label>
                                    <input
                                        id="notes"
                                        className={styles.inputField}
                                        placeholder="Notes"
                                        type="text"
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        required
                                        value={notes ? notes.toString().charAt(0).toUpperCase() + notes.toString().toLowerCase().slice(1) : ''}
                                        onChange={(e) => handleConsultOnChange('notes', e.target.value)} />

                                    <label htmlFor="initialConsultComplete">Initial Consult Complete</label>
                                    <select
                                        id="initialConsultComplete"
                                        style={{ border: 0, outline: 0 }}
                                        disabled={(mode === 'view&edit' && subMode === 'view')}
                                        className={styles.selectInputField}
                                        value={initialconsultcomplete ? initialconsultcomplete : ''}
                                        onChange={(e) => handleConsultOnChange('initialconsultcomplete', e.target.value)}>
                                        <option className={styles.option} value="">Select Initial Consult Complete</option>
                                        <option className={styles.option} value="Yes" selected={initialconsultcomplete === 'Yes' ? true : false}>Yes</option>
                                        <option className={styles.option} value="Scheduled" selected={initialconsultcomplete === 'Scheduled' ? true : false}>Scheduled</option>
                                    </select>
                                </div>
                            </div>
                            <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Status</h3></center>
                            <div style={{ marginBottom: '20px' }} className={styles.rowContainer}>
                                <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handleConsultOnChange('status', 'open') }} className={`${styles.inactiveButton} ${status === 'open' ? styles.activeButton : ''}`}>Open</div>
                                <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && handleConsultOnChange('status', 'resolved') }} className={`${styles.inactiveButton} ${status === 'resolved' ? styles.activeButton : ''}`}>Resolved</div>
                            </div>
                            {loading ?
                                <div style={{ margin: '3px' }}><Loader /></div>
                                :
                                (mode === 'add' || subMode === 'edit') &&
                                (
                                    <div onClick={() => submitForm()} style={{ marginBottom: '1.5rem', marginTop: '1rem' }} className={styles.rectangleBtn}>
                                        Save
                                    </div>
                                )
                            }
                            <div style={{ paddingBottom: '200px', height: '1px', width: '1px' }}></div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ConsultDetails
