import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css';
import GenericTable from '../reusable/GenericTable.js';
import styles1 from '../../assets/css/ChargesTab.module.css';
import styles2 from '../../assets/css/Admissions.module.css';
import styles3 from '../../assets/css/PatientList.module.css';
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate } from '../../helpers/index.js';
import Loader from '../reusable/Loader.js';
import { useNavigate } from 'react-router-dom';
import AdmissionDetails from './AdmissionDetails.js';

const Admissions = ({ patient, handleChoice }) => {
    const navigate = useNavigate();
    const [admissionsData, setAdmissionsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showAdmissionDetails, setShowAdmissionDetails] = useState(false);

    const fetchAdmissions = async (admission_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/admissions?admission_id=${admission_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            if (response.ok) {
                const data = await response.json();
                setAdmissionsData(data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching admissions data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdmissions(patient.admission_id);
    }, [patient.admission_id]);

    const ViewFacesheet = (row) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                id: row.patient_id,
                facesheetalias: row.facesheetalias
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/view-facesheet`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    window.open(data.signedUrl, '_blank');
                }, 100)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleMouseEnter = (row) => {
        setHoveredRowId(row.id); // Store the row ID instead of content
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const onCellClick = (row) => {
        setShowAdmissionDetails(true);
    };

    const columns = [
        {
            key: 'eye',
            label: <Eye style={{ visibility: 'hidden' }} fill={'var(--purple)'} className={styles3.listIcons} />,
            width: 0.01,
            render: (row) => (
                <div onClick={() => onCellClick(row)} className={styles.actionBtns}>
                    <Eye
                        fill={'var(--purple)'}
                        className={styles.listIcons}
                        style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                    />
                </div>
            )
        },
        {
            key: 'admitdate',
            label: 'Admit Date',
            width: 0.15,
            render: (row) => isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)
        },
        {
            key: 'location',
            label: 'Location',
            width: 0.3,
        },
        {
            key: 'owning_provider',
            label: 'Provider',
            width: 0.2,
        },
        {
            key: 'diagnoses',
            label: 'Diagnoses Codes',
            width: 0.2,
            render: (row) => (
                <div
                    onMouseEnter={() => handleMouseEnter(row)}
                    onMouseLeave={handleMouseLeave}
                >
                    {row.diagnoses && row.diagnoses.length > 0
                        ? row.diagnoses.map((r, index) => (
                            <span key={index}>
                                {r.code}
                                {(row.diagnoses.length - 1) !== index && ', '}
                            </span>
                        ))
                        : ' - '}
                    {hoveredRowId === row.id && row.diagnoses && row.diagnoses.length > 0 && (
                        <div className='hoverContent1'>
                            {row.diagnoses.map((d, index) => (
                                <span key={index}>
                                    {d.code} - {d.description}
                                    {(row.diagnoses.length - 1) !== index && <br />}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            )
        },
        {
            key: 'visittype',
            label: 'Visit Type',
            width: 0.2,
        },
        {
            key: 'discharge_date',
            label: 'Discharge Date',
            width: 0.2,
            render: (row) => isValidDate(row.dischargedate) && convertToCustomDate(row.dischargedate)
        },
        {
            key: 'facesheet',
            label: 'Facesheet',
            width: 0.1,
            render: (row) => (
                <div className={styles.actionBtns}>
                    {row.facesheetalias &&
                        <div onClick={() => ViewFacesheet(row)} className={styles.viewBtn}>
                            View
                        </div>
                    }
                </div>
            )
        },
    ];

    const handleNewAdmissionClick = () => {
        handleChoice('single');
        navigate('/patient', { replace: true, state: { admission_id: admissionsData[0].admission_id, mode: 'add', autoFillChoice: null, patient: null } });
    };

    if (loading) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}><Loader width={100} height={100} /></div>;
    }

    return (
        <div className={styles2.container}>
            {
                showAdmissionDetails ?
                    <AdmissionDetails /> :
                    <>
                        <div onClick={() => handleNewAdmissionClick()} style={{ gap: '1rem' }} className={[styles1.button, styles1.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>New Admission</div>
                        </div>
                        {
                            admissionsData.length > 0 &&
                            <GenericTable columns={columns} data={admissionsData} />
                        }
                    </>
            }
        </div>
    );
};

export default Admissions;
