import React, { useState } from 'react';
import PatientDetails from './PatientDetails';
import { ReactComponent as PatientDetailsLeftArrow } from '../../assets/icons/PatientDetailsLeftArrow.svg';
import { ReactComponent as PatientDetailsRightArrow } from '../../assets/icons/PatientDetailsRightArrow.svg';
import Toast from '../reusable/Toast';
import { getTokenFromLocalStorage } from '../../helpers';
import styles from '../../assets/css/Patient.module.css'
import { useNavigate } from 'react-router-dom';

const AddMultiplePatients = ({ patients }) => {
    const [patientIndex, setPatientIndex] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)
    const navigate = useNavigate();

    const changePatientIndex = (type) => {
        if (type === 'left') {
            if (patientIndex > 0) {
                setPatientIndex(patientIndex - 1);
            }
        } else {
            if (patientIndex < patients.length - 1) {
                setPatientIndex(patientIndex + 1);
            }
        }
    }

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const saveAllPatients = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: JSON.stringify({
                    patients: patients
                })
            };

            const result = await fetch(`${process.env.REACT_APP_API_URL}/users/save-multiple-patients`, requestOptions);
            if (result.ok) {
                showSuccessToast('Patients added successfully');
                if (addPatientToChargesPage) {
                    await addPatientIDToChargesPage();
                }
                navigate('/patient-list');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addPatientIDToChargesPage = async () => {
        const patientsPromiseArray = patients.map(p => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                },
                body: JSON.stringify({ patientId: p.id })
            };
            return fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, requestOptions);
        });

        const diagnosisPromiseArray = patients.map(p => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                },
                body: JSON.stringify({ patientId: p.id, selectedDiagnosis: p.selectedDiagnosis })
            };
            return fetch(`${process.env.REACT_APP_API_URL}/users/patient-diagnoses`, requestOptions);
        });

        try {
            const result = await Promise.all(patientsPromiseArray);
            if (result.length > 0 && result.every(r => r.ok)) {
                setTimeout(() => {
                    showSuccessToast('Patients added to charges page');
                }, 3500);
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                showErrorToast('Failed to add patient to charges page');
            }, 3500);
        }

        try {
            const result = await Promise.all(diagnosisPromiseArray);
            if (result.length > 0 && result.every(r => r.ok)) {
                setTimeout(() => {
                    showSuccessToast('Diagnosis added to patients');
                }, 3500);
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                showErrorToast('Failed to add diagnosis to patients');
            }, 3500);
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <PatientDetailsLeftArrow fill={patientIndex === 0 ? 'grey' : 'black'} onClick={() => changePatientIndex('left')} />
                <p>Patient ({patientIndex + 1} / {patients.length})</p>
                <PatientDetailsRightArrow fill={patientIndex === patients.length - 1 ? 'grey' : 'black'} onClick={() => changePatientIndex('right')} />
            </div>
            {
                patientIndex === patients.length - 1 &&
                <>
                    <div style={{ justifyContent: 'center', marginTop: '0.5rem' }} className={styles.checkboxContainer}>
                        <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                        <span>Add all patients to charges page</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <div style={{ backgroundColor: 'black' }} onClick={saveAllPatients} className="purple-btn">Save all</div>
                    </div>
                </>
            }


            <PatientDetails key={patientIndex} patient={patients[patientIndex]} mode={'add'} subMode={'addMultiplePatients'} selectedDiagnosisProps={patients[patientIndex]?.selectedDiagnosis} />
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </div>
    );
};

export default AddMultiplePatients;