import React from 'react';
import styles from '../../assets/css/GenericTable.module.css';

const GenericTable = ({
    columns,
    data,
    onSort,
    sortOrder,
    onCellClick,
    onMouseEnter,
    onMouseLeave,
    maxHeight = '100%',
    maxWidth = '100%',
    rowActions
}) => {
    return (
        <div className={styles.tableWrapper} style={{ maxHeight, maxWidth }}>
            <div className={styles.tableContainer}>
                <div className={styles.headerRow}>
                    {columns.map((column) => (
                        <div
                            key={column.key}
                            className={styles.headerCell}
                            style={{ flex: column.width || 1 }}
                            onClick={() => column.sortable && onSort(column.key)}
                        >
                            {column.label}
                            {column.sortable && sortOrder?.column === column.key &&
                                <span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>
                            }
                        </div>
                    ))}
                </div>
                <div className={styles.tableBody}>
                    {data.map((row, index) => (
                        <div
                            key={index}
                            className={styles.tableRow}
                            onClick={() => onCellClick && onCellClick(row)}
                            onMouseEnter={(e) => onMouseEnter && onMouseEnter(row)}
                            onMouseLeave={(e) => onMouseLeave && onMouseLeave(row)}
                        >
                            {columns.map((column) => (
                                <div
                                    key={column.key}
                                    className={styles.tableCell}
                                    style={{ flex: column.width }}
                                >
                                    {column.render ? column.render(row) : row[column.key]}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GenericTable;
