import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPatients } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import styles from '../../assets/css/PatientList.module.css'
import '../../App.css';
import TableRow from '../reusable/TableRow';
import DashboardStats from '../reusable/DashboardStats';

const PatientList = () => {
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [patientStatus, setPatientStatus] = useState('');
    const [originalPatients, setOriginalPatients] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: null, order: null });
    const [hideInactive, setHideInactive] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        getPatients().then((p) => {
            setOriginalPatients(p);
            const storedHideInactive = localStorage.getItem('hideInactive') === 'true';
            setHideInactive(storedHideInactive);

            if (storedHideInactive) {
                setPatientStatus('active');
                setPatients(p.filter(patient => patient.status === 'active'));
            } else {
                setPatientStatus('');
                setPatients(p);
            }
        });
    }, [navigate, hideInactive]);


    const handleSort = (column) => {
        let order = 'asc';
        if (sortOrder.column === column && sortOrder.order === 'asc') {
            order = 'desc';
        }

        setSortOrder({ column, order });

        const sortedPatients = [...patients].sort((a, b) => {
            if (column === 'facesheetalias') {
                if (a.facesheetalias === b.facesheetalias) {
                    const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }
                return order === 'asc'
                    ? (a.facesheetalias ? -1 : 1)
                    : (a.facesheetalias ? 1 : -1);
            }

            if (a[column] === null) return -1;
            if (b[column] === null) return -1;
            const locationA = a[column].toLowerCase();
            const locationB = b[column].toLowerCase();

            if (locationA < locationB) return order === 'asc' ? -1 : 1;
            if (locationA > locationB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });

        setPatients(sortedPatients);
    };

    const filterPatients = (searchTerm) => {
        const filteredPatients = originalPatients.filter(patient => {
            if (patientStatus !== '') {
                return (
                    (patient.firstname && patient.status === patientStatus && patient.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (patient.middlename && patient.status === patientStatus && patient.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (patient.lastname && patient.status === patientStatus && patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
            else {
                return (
                    (patient.firstname && patient.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (patient.middlename && patient.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (patient.lastname && patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
        });

        setPatients(filteredPatients);
    }

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterPatients(searchTerm);
    };

    const AddPatient = () => {
        navigate('/patient', { state: { patient: null, mode: 'add' } });
    }

    const togglePatientStatus = () => {
        setHideInactive(!hideInactive);
        let status = !hideInactive;
        // hide the patients whose status is equal to inactive
        localStorage.setItem("hideInactive", status);
        ChangePatientsBasedOnStatus(status);
    }

    const ChangePatientsBasedOnStatus = (status) => {
        if (status) {
            setPatientStatus('active');
            setPatients((prevPatients) =>
                prevPatients.filter(patient => patient.status === 'active')
            );
        } else {
            setPatientStatus('');
            setPatients(originalPatients);
            setSearchQuery('');
        }
    };


    return (
        <div className={styles.mainContainer}>
            <DashboardStats />
            <div className={`${styles.whiteContainer} whiteContainerWide`}>
                <div className="marginContainer">
                    <div className={[styles.topContainer1].join(' ')}>
                        <div className={styles.toggleContainer}>
                            <span>Edit Mode</span>
                            <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                                <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All patients</h3>
                        </div>
                        <div className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" value={searchQuery} placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                        <div className={styles.toggleContainer}>
                            <div
                                className={styles.tooltip}
                                onClick={() => setShowTooltip(!showTooltip)}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <InfoIcon height={20} />
                                <span style={{ marginTop: '3px' }}>Hide Inactive</span>
                                {showTooltip && (
                                    <span className={styles.tooltipText}>
                                        Hide patients that are no longer active in the system.
                                    </span>
                                )}
                            </div>
                            <div className="toggle" onClick={() => togglePatientStatus()}>
                                <div className={`toggle-slider ${hideInactive ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All patients</h3>
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                        <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div className={styles.toggleContainer}>
                            <div
                                className={styles.tooltip}
                                onClick={() => setShowTooltip(!showTooltip)}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <InfoIcon />
                                <span style={{ marginTop: '3px' }}>Hide Inactive</span>
                                {showTooltip && (
                                    <span className={styles.tooltipText}>
                                        Hide patients that are no longer active in the system.
                                    </span>
                                )}
                            </div>
                            <div className="toggle" onClick={() => togglePatientStatus()}>
                                <div className={`toggle-slider ${hideInactive ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tableContainer}>
                        <div className={styles.flexTable}>
                            <div id="tableHeader" className={styles.flexRow}>
                                <div className={styles.flexCell} style={{ flex: 0.1, marginRight: '4px' }}></div>
                                <div className={styles.flexCell} style={{ flex: 0.5 }} onClick={() => handleSort('lastname')}>
                                    Name
                                    {sortOrder.column === 'lastname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>DOB</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>Admit Date</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 1 }} onClick={() => handleSort('location')}>
                                    Location
                                    {sortOrder.column === 'location' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}</div>
                                <div className={styles.flexCell} style={{ flex: 0.3 }} onClick={() => handleSort('visittype')}>
                                    Visit Type
                                    {sortOrder.column === 'visittype' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}</div>
                                <div className={styles.flexCell} style={{ flex: 0.3 }} onClick={() => handleSort('status')}>
                                    Status
                                    {sortOrder.column === 'status' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: 0.3 }} onClick={() => handleSort('facesheetalias')}>
                                    Facesheet
                                    {sortOrder.column === 'facesheetalias' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                            </div>
                            {patients.length > 0 ? (
                                patients.map((patient, index) => (
                                    <TableRow
                                        key={index}
                                        patient={patient}
                                        isEditMode={isEditMode}
                                        setPatients={setPatients}
                                    />
                                ))
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6"></div>
        </div>
    );
};

export default PatientList;
