import React, { useEffect, useState } from 'react'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { getTokenFromLocalStorage } from '../../helpers';
import styles from '../../assets/css/ChargesVisitCodes.module.css'
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';

const ChargesVisitCodes = ({ setShowModal, selectedPatients, reRenderPatients }) => {
    const [visitCodes, setVisitCodes] = useState([]);
    const [selectedVisitCodes, setSelectedVisitCodes] = useState([]);

    useEffect(() => {
        fetchVisitCodes();
        let visitCodes = [];
        selectedPatients.map((p) => {
            return p.visit_codes.forEach(vc => {
                visitCodes.push(vc);
            })
        })
        //remove duplicates from visitCodes
        visitCodes = visitCodes.filter((visitCode, index, self) =>
            index === self.findIndex((t) => t.id === visitCode.id)
        );
        setSelectedVisitCodes(visitCodes);
    }, []);


    const fetchVisitCodes = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/visit-codes`, requestOptions);
        const visitCodes = await res.json();
        setVisitCodes(visitCodes);
    };

    const handleSave = async () => {
        try {
            let ids_array = [];
            if (selectedVisitCodes.length > 0) {
                selectedPatients.forEach((patient) => {
                    selectedVisitCodes.forEach((visitCode) => {
                        ids_array.push({
                            patientId: patient.id,
                            chargesId: visitCode.id,
                            charges_page_id: patient.charges_page_id,
                            admission_id: patient.admission_id
                        });
                    });
                    patient.visit_codes = selectedVisitCodes;
                });
            }
            else {
                selectedPatients.forEach((patient) => {
                    ids_array.push({
                        patientId: patient.id,
                        chargesId: null,
                        charges_page_id: patient.charges_page_id,
                        admission_id: patient.admission_id
                    });
                    patient.visit_codes = [];
                });
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: JSON.stringify({
                    ids_array
                })
            }
            await fetch(`${process.env.REACT_APP_API_URL}/users/patient-charges`, requestOptions);
            setShowModal(false);
            reRenderPatients(' Visit Codes Updated! ')
        } catch (error) {
            console.error('Error updating visit codes:', error);
            // Handle the error appropriately, e.g., show an error message to the user
        }
    }

    const handleVisitCode = (VisitCode, mode) => {
        if (mode === "delete") {
            const updatedVC = selectedVisitCodes.filter((vc) => VisitCode.id !== vc.id);
            setSelectedVisitCodes(updatedVC);
        }
        else {
            const alreadyAdded = selectedVisitCodes.find((vc) => VisitCode.id === vc.id);
            if (alreadyAdded) {
                return;
            }
            const updatedVC = [...selectedVisitCodes, VisitCode];
            setSelectedVisitCodes(updatedVC);
        }
    }


    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <h3>Select the Visit Codes :</h3>

                <div className={styles.DiagnosisContainer}>
                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Selected Visit Codes</p>
                            <div className={styles.checkmarkIcon} style={{ border: '1px solid red', borderRadius: '5px' }}><Trash onClick={() => setSelectedVisitCodes([])} width={20} height={20} fill={'red'} /></div>
                        </div>
                        {
                            selectedVisitCodes.map((item, index) => {
                                return (
                                    <div className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><Trash onClick={() => handleVisitCode(item, "delete")} width={20} height={20} fill={'#E52B50'} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Common Visit Codes</p>
                            <p className={styles.plus}></p>
                        </div>
                        {
                            visitCodes.map((item, index) => {
                                if (item.use === "common") {
                                    return (
                                        <div className={styles.DiagnosisItem} key={index}>
                                            <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                                            <div className={styles.DiagnosisItemDescription}> </div>
                                            <div className={styles.checkmarkIcon}><TickPending onClick={() => handleVisitCode(item, "add")} width={20} height={20} fill={selectedVisitCodes.some((vc) => vc.visit_code === item.visit_code) ? '#5e3ae4' : 'grey'} /></div>
                                        </div>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>

                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Rare Visit Codes</p>
                            <p className={styles.plus}></p>
                        </div>
                        {
                            visitCodes.map((item, index) => {
                                if (item.use === "rare") {
                                    return (
                                        <div className={styles.DiagnosisItem} key={index}>
                                            <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                                            <div className={styles.DiagnosisItemDescription}> </div>
                                            <div className={styles.checkmarkIcon}><TickPending onClick={() => handleVisitCode(item, "add")} width={20} height={20} fill={selectedVisitCodes.some((vc) => vc.visit_code === item.visit_code) ? '#5e3ae4' : 'grey'} /></div>
                                        </div>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>

                </div>

                <div onClick={() => handleSave()} className={[styles.button, styles.SaveButton].join(' ')}>
                    Save
                </div>
            </div>
        </div>
    )
}

export default ChargesVisitCodes
