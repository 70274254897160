import React from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import { convertToCustomDate, isValidDate } from '../../helpers'

const AddPatientTableRow = ({ patient, isSelected, onSelectChange }) => {

    const handleCheckboxChange = (event) => {
        onSelectChange(patient.id, event.target.checked);
    };

    function capitalizeNames(firstname, middlename, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    return (
        <>
            <div className={styles.flexRow}>
                <div className={styles.flexCell} style={{ flex: 0.1, justifyContent: 'center' }}>
                    <input className={styles.checkbox} type='checkbox' style={{ height: '15px', width: '15px' }} onChange={handleCheckboxChange} checked={isSelected} />
                </div>
                <div className={styles.flexCell} style={{ flex: 0.3 }}>
                    {capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}
                </div>
                <div className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</div>
                <div className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{patient.location}</div>
                <div className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{patient.visittype}</div>
                <div className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{patient.status}</div>
            </div >
        </>
    )
}

export default AddPatientTableRow
