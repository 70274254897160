import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import '../../App.css';
import TableRowForConsultsTracking from './TableRowForConsultsTracking';

const ConsultsTrackingTable = () => {
    const navigate = useNavigate();
    const [consults, setConsults] = useState([]);
    const [ConsultStatus, setConsultStatus] = useState('open');
    const [originalConsults, setOriginalConsults] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: null, order: null });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [hideResolved, setHideResolved] = useState(true);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            setConsults(p.filter(consults => consults.status === 'open'));
        });
    }, [navigate]);

    const fetchConsultsList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/consults-list`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            }
        } catch (error) {
            console.error('Error fetching consults:', error);
        }
    };

    const handleSort = (column) => {
        let order = sortOrder.column === column ?
            (sortOrder.order === 'desc' ? 'asc' : 'desc') :
            'desc';

        setSortOrder({ column, order });

        const sortedConsults = [...consults].sort((a, b) => {
            if (!a[column]) return 1;
            if (!b[column]) return -1;

            if (column === 'roomnumber') {
                const parseRoom = (room) => {
                    const parts = room.match(/([A-Za-z]+|\d+|[.-])/g) || [];
                    return parts.map(part =>
                        isNaN(part) ? part : part.padStart(5, '0')
                    ).join('');
                };

                const roomA = parseRoom(a[column] || '');
                const roomB = parseRoom(b[column] || '');

                return order === 'asc' ?
                    roomA.localeCompare(roomB) :
                    roomB.localeCompare(roomA);
            }

            if (['daterequested', 'visitdate'].includes(column)) {
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return order === 'asc' ? dateA - dateB : dateB - dateA;
            }

            if (column === 'timerequested') {
                const timeA = a[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                const timeB = b[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                return order === 'asc' ? timeA - timeB : timeB - timeA;
            }

            const valueA = a[column].toString().toLowerCase();
            const valueB = b[column].toString().toLowerCase();

            if (valueA < valueB) return order === 'asc' ? -1 : 1;
            if (valueA > valueB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });

        setConsults(sortedConsults);
    };

    const filterConsults = (searchTerm) => {
        const filteredConsults = originalConsults.filter(consults => {

            if (ConsultStatus !== '') {
                return (
                    (consults.firstname && consults.status === ConsultStatus && consults.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.middlename && consults.status === ConsultStatus && consults.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.lastname && consults.status === ConsultStatus && consults.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
            else {
                return (
                    (consults.firstname && consults.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.middlename && consults.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (consults.lastname && consults.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
        });

        setConsults(filteredConsults);
    }

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterConsults(searchTerm);
    };

    const AddConsult = () => {
        navigate('/consult-details', {
            state: {
                consults: null,
                mode: 'add',
            }
        });
    }

    const toggleConsultStatus = () => {
        setHideResolved(!hideResolved);
        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            if (hideResolved) {
                setConsultStatus('');
                setConsults(p);
            } else {
                setConsultStatus('open');
                setConsults(p.filter(consults => consults.status === 'open'));
            }
        });
    };

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.whiteContainer} whiteContainerWide`}>
                <div className="marginContainer">
                    <div className={[styles.topContainer1].join(' ')}>
                        <div className={styles.toggleContainer}>
                            <span>Edit Mode</span>
                            <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                                <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All Consults</h3>
                        </div>
                        <div className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" value={searchQuery} placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Consult
                        </div>
                        <div className={styles.toggleContainer}>
                            <span>Hide Resolved</span>
                            <div className="toggle" onClick={() => toggleConsultStatus()}>
                                <div className={`toggle-slider ${hideResolved ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All Consults</h3>
                        </div>
                        <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Consult
                        </div>
                        <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div className={styles.toggleContainer}>
                            <span>Hide Resolved</span>
                            <div className="toggle" onClick={() => toggleConsultStatus()}>
                                <div className={`toggle-slider ${hideResolved ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tableContainer}>
                        <div className={styles.flexTable}>
                            <div id="tableHeader" className={styles.flexRow}>
                                <div className={styles.flexCell} style={{ flex: '0.1', minWidth: '50px' }}></div>
                                <div className={styles.flexCell} style={{ flex: '1', minWidth: '150px' }} onClick={() => handleSort('lastname')}>
                                    Name {sortOrder.column === 'lastname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '2', minWidth: '200px' }} onClick={() => handleSort('hospitalfacilityname')}>
                                    Facility Name {sortOrder.column === 'hospitalfacilityname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '0.8', minWidth: '100px' }} onClick={() => handleSort('roomnumber')}>
                                    Room No. {sortOrder.column === 'roomnumber' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }}>DOB</div>
                                <div className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }} onClick={() => handleSort('daterequested')}>
                                    Date Requested {sortOrder.column === 'daterequested' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }} onClick={() => handleSort('timerequested')}>
                                    Time Requested {sortOrder.column === 'timerequested' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }} onClick={() => handleSort('visitdate')}>
                                    Visit Date {sortOrder.column === 'visitdate' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1.2', minWidth: '150px' }} onClick={() => handleSort('assignedprovider')}>
                                    Assigned Provider {sortOrder.column === 'assignedprovider' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }} onClick={() => handleSort('insurancecarrier')}>
                                    Insurance Carrier {sortOrder.column === 'insurancecarrier' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }} onClick={() => handleSort('rehabdiagnosis')}>
                                    Rehab Diagnosis {sortOrder.column === 'rehabdiagnosis' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }} onClick={() => handleSort('rehabrecs')}>
                                    Rehab Recommendations {sortOrder.column === 'rehabrecs' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1', minWidth: '120px' }} onClick={() => handleSort('notes')}>
                                    Notes {sortOrder.column === 'notes' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1.2', minWidth: '120px' }} onClick={() => handleSort('initialconsultcomplete')}>
                                    Initial Consult Complete {sortOrder.column === 'initialconsultcomplete' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: '1.2', minWidth: '110px' }} onClick={() => handleSort('status')}>
                                    Status
                                    {sortOrder.column === 'status' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                            </div>
                            {consults.length > 0 && consults.map((consults, index) => (
                                <TableRowForConsultsTracking
                                    key={index}
                                    consults={consults}
                                    isEditMode={isEditMode}
                                    setConsults={setConsults}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6"></div>
        </div>
    );
};

export default ConsultsTrackingTable;
