import DOMPurify from 'dompurify';
import { format, isValid, parseISO, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const isValidJWTToken = (token) => {
    const tokenParts = token.split('.');
    return tokenParts.length === 3;
}

export const sanitizeToken = (token) => {
    return DOMPurify.sanitize(token);
}

export const encodeToken = (token) => {
    return btoa(token);
}

export const getTokenFromLocalStorage = () => {
    const encodedToken = localStorage.getItem('token');
    if (encodedToken) {
        const decodedToken = atob(encodedToken);
        return decodedToken;
    }
    return null;
}

export const storeTokenInLocalStorage = (token) => {
    // Validate the token structure
    if (!isValidJWTToken(token)) {
        console.error('Invalid JWT token structure');
        return;
    }

    // Sanitize the token value
    const sanitizedToken = sanitizeToken(token);

    // Encode the token (optional)
    const encodedToken = encodeToken(sanitizedToken);

    // Store the sanitized and encoded token in localStorage
    localStorage.setItem('token', encodedToken);
}

export const isValidDate = (dateString) => {
    return isValid(new Date(dateString));
};

export const convertToCalendarSpecificDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    const zonedDate = toZonedTime(date, 'UTC');
    const formattedDate = format(zonedDate, 'yyyy-MM-dd');
    return formattedDate;
};

export const convertToCustomDate = (dateString) => {
    if (!dateString) return '';
    const datePart = dateString.split('T')[0];
    const date = parseISO(datePart);
    const formattedDate = format(date, 'MM-dd-yyyy');
    return formattedDate;
}

export const ViewCalendarDate = (dateString) => {
    if (!dateString) return '';
    const datePart = dateString.split('T')[0];
    const date = parseISO(datePart);
    const formattedDate = format(date, 'yyyy-MM-dd');
    return formattedDate;
};

export const getPatients = async () => {
    const accessToken = getTokenFromLocalStorage();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/patients-list`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    const data = await response.json();
    return data;
};

export function capitalizeNames(firstname, middlename, lastname) {
    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
    const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
    const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
    return `${capitalizedLastName}, ${capitalizedFirstName}`;
}

export const keepOnlyNumbers = (input) => {
    if (input === null || input === undefined) return '';
    return input.toString().replace(/\D/g, '');
};

export const setupInactivityTimer = (navigate) => {
    let inactivityTimeout;
    const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

    const resetTimer = () => {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => {
            localStorage.removeItem('token');
            navigate('/signin');
        }, TIMEOUT_DURATION);
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    events.forEach(event => {
        document.addEventListener(event, resetTimer);
    });

    resetTimer();

    // Cleanup function
    return () => {
        events.forEach(event => {
            document.removeEventListener(event, resetTimer);
        });
        clearTimeout(inactivityTimeout);
    };
};

export const handleDates = (date, mode, subMode) => {
    if ((mode === 'view&edit' && subMode === 'edit') || (mode === 'add' && subMode === 'addMultiplePatients')) {
        return (convertToCalendarSpecificDate(date));
    }
    if ((mode === 'view&edit' && subMode === 'view') || (mode === 'add' && subMode === 'view')) {
        return (ViewCalendarDate(date));
    }
}