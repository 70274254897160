import React, { useEffect, useState } from 'react'
import Toast from '../reusable/Toast';
import styles from '../../assets/css/Patient.module.css'
import Diagnosis from '../reusable/Diagnosis';
import { convertToCalendarSpecificDate, getTokenFromLocalStorage, isValidDate, keepOnlyNumbers, handleDates } from '../../helpers/index'
import Loader from '../reusable/Loader';

const PatientDetails = ({ patient, subMode, mode, selectedDiagnosisProps = [] }) => {

    let id;
    if (patient) {
        id = patient.id;
        patient.status === null && (patient.status = 'active');
    }
    const [firstname, setFirstName] = useState(patient && patient.firstname ? patient.firstname : null)
    const [lastname, setLastName] = useState(patient && patient.lastname ? patient.lastname : null)
    const [middlename, setMiddleName] = useState(patient && patient.middlename ? patient.middlename : null)
    const [age, setAge] = useState(patient && patient.age ? keepOnlyNumbers(patient.age) : null)
    const [gender, setGender] = useState(patient && patient.gender ? patient.gender.toLowerCase() : null)
    const [dateofbirth, setDob] = useState(patient && patient.dateofbirth ? convertToCalendarSpecificDate(patient.dateofbirth) : null)
    const [status, setStatus] = useState(patient && patient.status && patient.status ? patient.status : 'active')
    const [loading, setLoading] = useState(false)
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(selectedDiagnosisProps);
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    useEffect(() => {
        fetchPatientDiagnoses();
    }, [])

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const fetchPatientDiagnoses = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/patient-diagnoses?patientId=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            setSelectedDiagnosis(data);
        } else {
            console.error('Error:', response.status);
        }
    }

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleSelectedDiagnosis = (diagArray) => {
        patient.selectedDiagnosis = diagArray;
        setSelectedDiagnosis(diagArray);
    };

    const submitForm = async () => {

        if (firstname === null || firstname === '') {
            showErrorToast('Please enter first name');
            return;
        }

        if (lastname === null || lastname === '') {
            showErrorToast('Please enter last name');
            return;
        }

        if (dateofbirth === null || dateofbirth === '') {
            showErrorToast('Please select date of birth');
            return;
        }

        const token = getTokenFromLocalStorage()

        if (id === '') {
            showErrorToast('Patient ID is missing');
            return;
        }

        const formData = new URLSearchParams();
        formData.append('id', id);
        if (firstname) { formData.append('firstname', firstname); }
        if (lastname) { formData.append('lastname', lastname); }
        if (middlename) { formData.append('middlename', middlename); }
        if (gender) { formData.append('gender', gender); }
        if (dateofbirth) { formData.append('dateofbirth', dateofbirth); }
        if (status) { formData.append('status', status); }
        if (age) { formData.append('age', age); }

        try {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-patient`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + token,
                },
                body: formData.toString(),
            });

            const rawData = response;
            const jsonResponse = await rawData.json();
            if (jsonResponse.success === true) {
                setLoading(false);
                showSuccessToast('Patient updated successfully');
                await saveDiagnosis(jsonResponse.admission_id);
                if (addPatientToChargesPage) {
                    await addPatientIDToChargesPage(jsonResponse.admission_id);
                }
            } else {
                setLoading(false);
                showErrorToast('Failed to update patient');
            }
        } catch (error) {
            setLoading(false);
            showErrorToast('An error occurred while updating patient');
        }
    }

    const addPatientIDToChargesPage = async (admission_id) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            },
            body: JSON.stringify({ patientId: id, admission_id })
        };

        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, requestOptions);
            const response = await result.json();
            if (result.ok) {
                setTimeout(() => {
                    showSuccessToast('Patient added to charges page');
                }, 1500);
            }
            else {
                setTimeout(() => {
                    showErrorToast(response.message);
                }, 1500);
            }
        } catch (error) {
            showErrorToast(error.message);
        }
    }

    const saveDiagnosis = async (admission_id) => {
        setLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            };
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ patientId: id, selectedDiagnosis, admission_id })
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/patient-diagnoses`, requestOptions);
            if (response.ok) {
                setLoading(false);
                if (selectedDiagnosis.length > 0) {
                    showSuccessToast('Diagnosis Saved!');
                }
            } else {
                setLoading(false)
                console.error('Error:', response.status);
                showErrorToast('Error saving diagnosis');
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            showErrorToast('Error saving diagnosis');
        }

    }

    const handlePatientOnChange = (patient_key, value) => {

        if (patient_key === 'firstname') {
            setFirstName(value);
            patient.firstname = value;
        }

        if (patient_key === 'lastname') {
            setLastName(value);
            patient.lastname = value;
        }

        if (patient_key === 'middlename') {
            setMiddleName(value);
            patient.middlename = value;
        }

        if (patient_key === 'age') {
            value = keepOnlyNumbers(value);
            setAge(value);
            patient.age = value;
        }

        if (patient_key === 'dateofbirth') {
            setDob(value);
            value = handleDates(dateofbirth, mode, subMode);
            patient.dob = value;
        }

        if (patient_key === 'gender') {
            setGender(value);
            patient.gender = value;
        }

        if (patient_key === 'status') {
            setStatus(value);
            patient.status = value;
        }

    };


    return (
        <div style={{ justifyContent: 'flex-start', backgroundColor: 'white', height: '100%', borderRadius: '40px' }} className={styles.content}>
            {showToast && <Toast message={toastMessage} type={toastType} />}
            <form onSubmit={submitForm} className={styles.subContent}>
                <center><h3 style={{ marginBottom: '3rem', marginTop: '3rem' }}>Patient Details</h3></center>
                <div className={styles.columnContainer}>
                    <div className={styles.inputWrapper}>
                        <label htmlFor="firstName">First Name<span className={styles.required}>*</span></label>
                        <input
                            id="firstName"
                            className={styles.inputField}
                            placeholder="First Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={firstname ? firstname.toString().charAt(0).toUpperCase() + firstname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('firstname', e.target.value)} />

                        <label htmlFor="middleName">Middle Name</label>
                        <input
                            id="middleName"
                            className={styles.inputField}
                            placeholder="Middle Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={middlename ? middlename.toString().charAt(0).toUpperCase() + middlename.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('middlename', e.target.value)} />

                        <label htmlFor="dateOfBirth">Date of Birth<span className={styles.required}>*</span></label>
                        <input
                            id="dateOfBirth"
                            placeholder="Date of Birth"
                            type="date"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.dateField}
                            required
                            value={dateofbirth && isValidDate(dateofbirth) ? handleDates(dateofbirth, mode, subMode) : ''}
                            onChange={(e) => handlePatientOnChange('dateofbirth', e.target.value)} />
                    </div>

                    <div className={styles.inputWrapper}>
                        <label htmlFor="lastName">Last Name<span className={styles.required}>*</span></label>
                        <input
                            id="lastName"
                            className={styles.inputField}
                            placeholder="Last Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={lastname ? lastname.toString().charAt(0).toUpperCase() + lastname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('lastname', e.target.value)} />

                        <label htmlFor="age">Age</label>
                        <input
                            id="age"
                            className={styles.inputField}
                            placeholder="age"
                            type="number"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={keepOnlyNumbers(age)}
                            onChange={(e) => handlePatientOnChange('age', e.target.value)} />

                        <label htmlFor="gender">Gender</label>
                        <select
                            id="gender"
                            style={{ border: 0, outline: 0 }}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.selectInputField}
                            value={gender ? gender : ''}
                            onChange={(e) => handlePatientOnChange('gender', e.target.value)}>
                            <option className={styles.option} value="">Select Gender</option>
                            <option className={styles.option} value="m" selected={gender === 'm' ? true : false}>Male</option>
                            <option className={styles.option} value="f" selected={gender === 'f' ? true : false}>Female</option>
                            <option className={styles.option} value="o" selected={gender === 'o' ? true : false}>Other</option>
                        </select>

                    </div>
                </div>

                <div className={styles.diagnosisContainer} style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>
                    <Diagnosis subMode={subMode} mode={mode} DiagnosisArray={selectedDiagnosis} handleSelectedDiagnosis={handleSelectedDiagnosis} />
                </div>

                <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Status</h3></center>
                <div style={{ marginBottom: '20px' }} className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('status', 'active') }} className={`${styles.inactiveButton} ${status === 'active' ? styles.activeButton : ''}`}>Active</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('status', 'inactive') }} className={`${styles.inactiveButton} ${status === 'inactive' ? styles.activeButton : ''}`}>Inactive</div>
                </div>

                {
                    (mode === 'add' || subMode === 'edit') && subMode !== 'addMultiplePatients' && (
                        <div className={styles.checkboxContainer}>
                            <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                            <span>Add this patient to charge page</span>
                        </div>
                    )
                }

                {loading ?
                    <div style={{ margin: '3px' }}><Loader /></div>
                    :
                    (mode === 'add' || subMode === 'edit') && subMode !== 'addMultiplePatients' &&
                    (
                        <div onClick={() => submitForm()} style={{ marginBottom: '1.5rem', marginTop: '1rem' }} className={styles.rectangleBtn}>
                            Save
                        </div>
                    )
                }
                <div style={{ paddingBottom: '100px', height: '1px', width: '1px' }}></div>

            </form>
        </div >
    )
}

export default PatientDetails
