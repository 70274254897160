import React from 'react'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate } from '../../helpers'

const TableRowForConsultsTracking = ({ consults, isEditMode, setConsults }) => {
    const navigate = useNavigate();

    const EditDetails = (consults) => {
        navigate('/consult-details', {
            state: {
                consults: consults,
                mode: 'view&edit',
            }
        });
    };

    const handleStatusToggle = async () => {
        const newStatus = consults.status === 'open' ? 'resolved' : 'open';
        const formData = new URLSearchParams();

        if (consults.id) formData.append('id', consults.id);
        if (consults.hospitalfacilityname) formData.append('hospitalfacilityname', consults.hospitalfacilityname);
        if (consults.roomnumber) formData.append('roomnumber', consults.roomnumber);
        if (consults.firstname) formData.append('firstname', consults.firstname);
        if (consults.lastname) formData.append('lastname', consults.lastname);
        if (consults.dob) formData.append('dob', consults.dob);
        if (consults.daterequested) formData.append('daterequested', consults.daterequested);
        if (consults.timerequested) formData.append('timerequested', consults.timerequested);
        if (consults.visitdate) formData.append('visitdate', consults.visitdate);
        if (consults.assignedprovider) formData.append('assignedprovider', consults.assignedprovider);
        if (consults.insurancecarrier) formData.append('insurancecarrier', consults.insurancecarrier);
        if (consults.rehabdiagnosis) formData.append('rehabdiagnosis', consults.rehabdiagnosis);
        if (consults.rehabrecs) formData.append('rehabrecs', consults.rehabrecs);
        if (consults.notes) formData.append('notes', consults.notes);
        if (consults.initialconsultcomplete) formData.append('initialconsultcomplete', consults.initialconsultcomplete);
        formData.append('status', newStatus);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setConsults((prevConsults) =>
                    prevConsults.map((list) =>
                        list.id === consults.id ? { ...list, status: newStatus } : list
                    )
                );
            } else {
                throw new Error('Failed to update consults status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    function capitalizeNames(firstname, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    return (
        <div className={styles.flexRow}>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '0.1', minWidth: '50px' }}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1', minWidth: '150px' }}>
                {capitalizeNames(consults.firstname, consults.lastname)}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '2', minWidth: '200px' }}>
                {consults.hospitalfacilityname}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '0.8', minWidth: '100px' }}>
                {consults.roomnumber}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }}>
                {isValidDate(consults.dob) && convertToCustomDate(consults.dob)}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }}>
                {isValidDate(consults.daterequested) && convertToCustomDate(consults.daterequested)}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }}>
                {consults.timerequested}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1', minWidth: '120px' }}>
                {isValidDate(consults.visitdate) && convertToCustomDate(consults.visitdate)}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1.2', minWidth: '150px' }}>
                {consults.assignedprovider}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }}>
                {consults.insurancecarrier}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }}>
                {consults.rehabdiagnosis}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1.2', minWidth: '150px' }}>
                {consults.rehabrecs}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: '1', minWidth: '120px' }}>
                {consults.notes}
            </div>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ flex: '1.2', minWidth: '120px' }}>
                {consults.initialconsultcomplete}
            </div>
            <div className={[styles.flexCell].join(' ')} style={{ flex: '1', minWidth: '120px' }}>
                {isEditMode ? (
                    <div
                        className={`${styles.toggleSwitch} ${consults.status === 'open' ? styles.active : styles.inactive}`}
                        onClick={handleStatusToggle}
                    >
                        <div className={styles.toggleButton}></div>
                        <span className={styles.toggleLabel}>
                            {consults.status === 'open' ? 'open' : 'resolved'}
                        </span>
                    </div>
                ) : (
                    consults.status
                )}
            </div>
        </div>
    )
};

export default TableRowForConsultsTracking
