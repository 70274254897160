import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HomeNavbar } from './components/reusable/HomeNavbar';
import SignUp from './components/main/SignUp';
import SignIn from './components/main/SignIn';
import PatientList from './components/main/PatientList';
import Charges from './components/main/Charges';
import SideBar from './components/reusable/Sidebar';
import Patient from './components/main/Patient';
import Profile from './components/reusable/Profile';
import Utilities from './components/single/Utilities';
import ConsultDetails from './components/single/ConsultDetails';
import AppointmentSetter from './components/reusable/AppointmentSetter';
import ConsultsTrackingTable from './components/reusable/ConsultsTrackingTable';
import EmployeeCalendar from './components/reusable/EmployeeCalendar';
import StaffManager from './components/reusable/StaffManager/StaffManager';
import { useNavigate } from 'react-router-dom';
import { setupInactivityTimer } from './helpers';

const SubApp = () => {
    const location = useLocation();
    const [flexDirection, setFlexDirection] = useState('column');
    const [isMobile, setIsMobile] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const cleanup = setupInactivityTimer(navigate);
        return cleanup;
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 631) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize();
        if (isMobile) {
            setFlexDirection('column-reverse');
        } else if (location.pathname === '/patient-list' || location.pathname === '/patient' || location.pathname === '/charges' || location.pathname === '/profile' || location.pathname === '/utilities') {
            setFlexDirection('row');
        } else {
            setFlexDirection('column');
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [location.pathname])

    return (
        <>
            <HomeNavbar />
            <div className={`App ${flexDirection === 'column-reverse' ? 'flex-column-reverse' : ''} ${flexDirection === 'column' ? 'flex-column' : 'flex-row'}`} >
                <SideBar isMobile={isMobile} />
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/patient-list" element={<PatientList />} />
                    <Route path="/patient" element={<Patient />} />
                    <Route path="/charges" element={<Charges />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/utilities" element={<Utilities />} />
                    <Route path="/appointment" element={<AppointmentSetter />} />
                    <Route path="/consults-tracking-table" element={<ConsultsTrackingTable />} />
                    <Route path="/consult-details" element={<ConsultDetails />} />
                    <Route path="/calendar" element={<EmployeeCalendar />} />
                    <Route path="/staff-users" element={<StaffManager />} />
                </Routes>
            </div>
        </>
    );
}

export default SubApp;
