import React, { useState } from 'react';
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import styles from '../../assets/css/ChargesTab.module.css';
import { useNavigate } from 'react-router-dom';
import { convertToCustomDate, isValidDate } from '../../helpers';

const ChargesTable = ({ patient, onSelect, isSelected }) => {
    const navigate = useNavigate();
    const [hoverContent, setHoverContent] = useState(null);

    function capitalizeNames(firstname, middlename, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    const EditDetails = (patient) => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true } });
    };

    const shared_visits = (patient) => {
        return patient && patient.shared_visits && patient.shared_visits.length > 0 ?
            patient.shared_visits.map((visit, index) => (
                <div key={index}>{visit.name}</div>
            )) : '-';
    };

    const handleMouseEnter = () => {
        const content = patient.visit_codes && patient.visit_codes.length > 0
            && patient.visit_codes.map((c, index) => (
                <span key={index}>
                    {c.visit_code} - {c.description}
                    {(patient.visit_codes.length - 1) !== index && <br />}
                </span>
            ));
        setHoverContent(content);
    };

    const handleMouseLeave = () => {
        setHoverContent(null);
    };

    return (
        <>
            <div className={styles.flexRow}>
                <div className={styles.flexCell} style={{ flex: 0.1, justifyContent: 'center' }}>
                    <input className={styles.checkbox} onChange={(e) => onSelect(e.target.checked)} type='checkbox' style={{ height: '15px', width: '15px' }} checked={isSelected} />
                </div>
                <div className={[styles.flexCell, styles.flexName].join(' ')} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Eye fill={'var(--purple)'} onClick={() => EditDetails(patient)} className={styles.eyeIcon} />
                    {capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}
                </div>
                <div
                    className={[styles.flexCell, styles.border].join(' ')}
                    style={{ flex: 0.3, flexWrap: 'wrap', justifyContent: 'center' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {patient.visit_codes && patient.visit_codes.length > 0
                        ? patient.visit_codes.map((c, index) => (
                            <span key={index}>
                                {c.visit_code}
                                {(patient.visit_codes.length - 1) !== index && ', '}
                            </span>
                        ))
                        : ' - '}
                    {hoverContent && (
                        <div className='hoverContent'>
                            {hoverContent}
                        </div>
                    )}
                </div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2, justifyContent: 'center' }}>{shared_visits(patient)}</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.3 }}>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.4, fontSize: '0.55rem' }}>{patient.location}</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>{patient.visittype}</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>{patient.status}</div>
                <div className={styles.flexCell} style={{ flex: 0.2, justifyContent: 'center' }}>{patient.facesheetalias ? <Tick fill={'green'} /> : <Cross fill={'red'} />}</div>
            </div>
        </>
    );
};

export default ChargesTable;