import React, { useState } from 'react';
import styles from '../../assets/css/Patient.module.css'
import s2 from '../../assets/css/Admissions.module.css';
import { convertToCalendarSpecificDate, isValidDate, handleDates } from '../../helpers';

const AdmissionDetails = ({ patient, subMode, mode }) => {

    let id;
    if (patient) {
        id = patient.id;
        patient.status === null && (patient.status = 'active');
    }
    const [admitdate, setAdmitDate] = useState(patient && patient.admitdate ? convertToCalendarSpecificDate(patient.admitdate) : null)
    const [room, setRoom] = useState(patient && patient.room ? patient.room : null)
    const [location, setLocation] = useState(patient && patient.location ? patient.location : null)
    const [dischargedate, setDischargeDate] = useState(patient && patient.dischargedate ? convertToCalendarSpecificDate(patient.dischargedate) : null)
    const [visittype, setVisitType] = useState(patient && patient.visittype ? patient.visittype : null)
    const [facesheetalias, setFacesheetAlias] = useState(patient && patient.facesheetalias ? patient.facesheetalias : null)
    const [owningProvider, setOwningProvider] = useState(patient && patient.owning_provider ? patient.owning_provider : null)

    const handlePatientOnChange = (patient_key, value) => {

        if (patient_key === 'room') {
            setRoom(value);
            patient.room = value;
        }

        if (patient_key === 'location') {
            setLocation(value);
            patient.location = value;
        }

        if (patient_key === 'admitdate') {
            setAdmitDate(value);
            value = handleDates(admitdate, mode, subMode);
            patient.admitdate = value;
        }

        if (patient_key === 'dischargedate') {
            setDischargeDate(value);
            value = handleDates(dischargedate, mode, subMode);
            patient.dischargedate = value;
        }

        if (patient_key === 'visittype') {
            setVisitType(value);
            patient.visittype = value;
        }

        if (patient_key === 'owning_provider') {
            setOwningProvider(value);
            patient.owning_provider = value;
        }

    };


    return (
        <div className={s2.detailsContainer}>
            <div className={s2.blockContainer}>
                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <label htmlFor="admitDate">Admit Date<span className={styles.required}>*</span></label>
                    <input
                        id="admitDate"
                        className={styles.dateField}
                        placeholder="Admit Date"
                        type="date"
                        disabled={(mode === 'view&edit' && subMode === 'view')}
                        required
                        value={admitdate && isValidDate(admitdate) ? handleDates(admitdate) : ''}
                        onChange={(e) => handlePatientOnChange('admitdate', e.target.value)} />
                </div>

                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <label htmlFor="placeOfService">Place of Service<span className={styles.required}>*</span></label>
                    <select
                        id="placeOfService"
                        className={styles.inputField}
                        disabled={(mode === 'view&edit' && subMode === 'view')}
                        required
                        value={location ? location : ''}
                        onChange={(e) => handlePatientOnChange('location', e.target.value)}
                    >
                        <option value="">Select Place of Service</option>
                        <option value="Chippenham Hospital">Chippenham Hospital</option>
                        <option value="Encompass Health Rehabilitation Hospital of Petersburg">Encompass Health Rehabilitation Hospital of Petersburg</option>
                        <option value="Henrico Doctors Hospital">Henrico Doctors Hospital</option>
                        <option value="Johnston Willis Hospital">Johnston Willis Hospital</option>
                        <option value="Parham Doctors Hospital">Parham Doctors Hospital</option>
                        <option value="Southside Regional Medical Center">Southside Regional Medical Center</option>
                    </select>
                </div>

                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <label htmlFor="admitDate">Owning Provider<span className={styles.required}>*</span></label>
                    <select
                        style={{ border: 0, outline: 0 }}
                        className={[styles.selectInputField].join(' ')}
                        disabled={(mode === 'view&edit' && subMode === 'view')}
                        value={owningProvider ? owningProvider : ''}
                        onChange={(e) => handlePatientOnChange('owning_provider', e.target.value)}
                    >
                        <option value="">Select Owning Provider</option>
                        <option value="Dr. John Smith">Dr. John Smith</option>
                        <option value="Dr. Jane Doe">Dr. Jane Doe</option>
                    </select>
                </div>

                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <h3>Visit Type<span className={styles.required}>*</span></h3>
                    <div className={styles.rowContainer}>
                        <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'inpatient') }} className={`${styles.inactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                        <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'consult') }} className={`${styles.inactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                    </div>
                </div>

                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <label htmlFor="dischargeDate">Discharge Date</label>
                    <input
                        id="dischargeDate"
                        className={styles.dateField}
                        placeholder="Discharge Date"
                        type="date"
                        disabled={(mode === 'view&edit' && subMode === 'view')}
                        value={dischargedate && isValidDate(dischargedate) ? handleDates(dischargedate, mode, subMode) : ''}
                        onChange={(e) => handlePatientOnChange('dischargedate', e.target.value)} />
                </div>


                <div className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <label htmlFor="room">Room</label>
                    <input
                        id="room"
                        className={styles.inputField}
                        placeholder="room"
                        type="text"
                        disabled={(mode === 'view&edit' && subMode === 'view')}
                        value={room ? room : ''}
                        onChange={(e) => handlePatientOnChange('room', e.target.value)} />
                </div>

                <div style={{ alignItems: 'center' }} className={[s2.inputFieldContainer, 'mt1'].join(' ')}>
                    <div className='purple-btn'>Save</div>
                </div>
            </div>
        </div>
    );
};

export default AdmissionDetails;